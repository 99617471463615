import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CANDIDATES } from "../Queries/Candidate";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../typography/title";
import CandidateModal from "./CandidateModal";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import { formatPhoneNumber } from "react-phone-number-input";
import Pagination from "../../utils/Pagination";

const Candidates = () => {
  let navigate = useNavigate();
  let team = localStorage.getItem("team");
  if (team) team = JSON.parse(team);
  let team_id = team.teamId;

  let pageno = parseInt(localStorage.getItem("pageno")) || 0;
  let pagelimit = parseInt(localStorage.getItem("pagelimit")) || 20;
  const [orderBy, setOrderBy] = useState("asc");
  const [orderColumn, setOrderColumn] = useState("last_name");

  if (pageno) pageno = parseInt(pageno);
  const [pageCount, setPageCount] = useState(null);
  const [pageNo, setPageNo] = useState(pageno);
  const [limit, setLimit] = useState(pagelimit);
  const [teamId] = useState(team_id);

  const { loading, error, data, refetch } = useQuery(GET_CANDIDATES, {
    variables: {
      page: parseInt(pageNo),
      limit: parseInt(limit),
      teamId: parseInt(teamId),
      orderBy,
      orderColumn
    }
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // set the total page count based on the number of candidates returned
  if (data) {
    if (!pageCount && data.getAllCandidates.total) {
      const pgCount = Math.ceil(data.getAllCandidates.total / limit);
      setPageCount(pgCount);
    }
  }

  const handleClickRow = (row) => {
    navigate(`/candidates/${row.id}`);
  };

  const handlePageClick = (event) => {
    pageno = event.selected;
    setPageNo(pageno);
    localStorage.setItem("pageno", pageno);
  };

  const handleSetLimit = (e) => {
    if (e && e > 0) {
      setLimit(parseInt(e));
      localStorage.setItem("pagelimit", parseInt(limit));
    }
  };

  const handleHeaderClick = (e) => {
    setOrderBy(orderBy === "asc" ? "desc" : "asc");

    if (e === "first") {
      setOrderColumn("first_name");
    } else if (e === "last") {
      setOrderColumn("last_name");
    } else {
      setOrderColumn(e);
    }

    refetch();
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <React.Fragment>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Title>Candidates </Title>
            <CandidateModal />
          </Box>
          <div
            className="table-container"
            style={{ height: "60vh", overflowY: "auto" }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell onClick={(e) => handleHeaderClick("first")}>
                    First
                  </TableCell>
                  <TableCell onClick={(e) => handleHeaderClick("last")}>
                    Last
                  </TableCell>
                  <TableCell
                    onClick={(e) => handleHeaderClick("email")}
                    sx={{ display: { xs: "none", sm: "table-cell" } }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    onClick={(e) => handleHeaderClick("phone")}
                    sx={{ display: { xs: "none", sm: "table-cell" } }}
                  >
                    Phone
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.getAllCandidates.candidates.map((row) => (
                  <TableRow key={row.id} onClick={() => handleClickRow(row)}>
                    <TableCell>
                      {row.image ? (
                        <Avatar src={row.image} />
                      ) : (
                        <Avatar sx={{ bgcolor: deepOrange[500] }}>
                          {row.first_name[0].toUpperCase()}
                        </Avatar>
                      )}
                    </TableCell>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell
                      sx={{ display: { xs: "none", sm: "table-cell" } }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      sx={{ display: { xs: "none", sm: "table-cell" } }}
                    >
                      {formatPhoneNumber(row.phone) || row.phone}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="flex">
            {pageCount > 1 ? (
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                limit={limit}
                forcePage={pageNo}
                handleSetLimit={handleSetLimit}
              />
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
      </Paper>
    </Grid>
  );
};

export default Candidates;
