import React from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import moment from "moment";
import {
  deepOrange,
  deepPurple,
  green,
  blue,
  pink
} from "@mui/material/colors";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import NoteIcon from "@mui/icons-material/Note";
import SmsIcon from "@mui/icons-material/Sms";
import FiberNewIcon from "@mui/icons-material/FiberNew";

export default function CandidateNote({ notes, candidateCreated }) {
  const avatarType = (note) => {
    if (note.note_type === "email") {
      return (
        <Avatar sx={{ bgcolor: deepOrange[500] }}>
          <EmailIcon />
        </Avatar>
      );
    } else if (note.note_type === "call") {
      return (
        <Avatar sx={{ bgcolor: deepPurple[500] }}>
          <PhoneIcon />
        </Avatar>
      );
    } else if (note.note_type === "text") {
      return (
        <Avatar sx={{ bgcolor: green[500] }}>
          <SmsIcon />
        </Avatar>
      );
    } else {
      return (
        <Avatar sx={{ bgcolor: blue[500] }}>
          <NoteIcon />
        </Avatar>
      );
    }
  };

  return (
    <React.Fragment>
      {notes.map((note) => (
        <Grid container key={note.id} sx={{ paddingBottom: "20px" }}>
          <Grid item xs={1} sm={1} md={1}>
            {avatarType(note)}
          </Grid>
          <Grid item xs={11} sm={11} md={11}>
            <Box sx={{ paddingLeft: "12px" }}>{note.note}</Box>
            <Box
              sx={{
                paddingLeft: "12px",
                borderBottom: "1px dotted #b9b7b6",
                fontSize: "12px"
              }}
            >
              {moment(parseInt(note.createdAt)).format("MMM DD, YYYY h:mm a")}
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid container sx={{ paddingBottom: "20px" }}>
        <Grid item xs={1} sm={1} md={1}>
          <Avatar sx={{ bgcolor: pink[500] }}>
            <FiberNewIcon />
          </Avatar>
        </Grid>
        <Grid item xs={11} sm={11} md={11}>
          <Box sx={{ paddingLeft: "12px" }}>Candidate Created</Box>
          <Box
            sx={{
              paddingLeft: "12px",
              fontSize: "12px"
            }}
          >
            {moment(candidateCreated).format("MMM DD, YYYY h:mm a")}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
