import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <h3>Look like you're lost</h3>
    <p>The page you are looking for not available!</p>            
    <Link to="/">Go Home</Link>
  </div>
);

export default NotFound;