import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Link } from "react-router-dom";

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const buildTitle = (event) => {
  return `${event.title} ${event.candidate.first_name} ${event.candidate.last_name}`;
};

function Event({ event }) {
  return <span>{buildTitle(event)}</span>;
}

function EventAgenda({ event }) {
  return (
    <div>
      <div style={{ color: "magenta" }}>
        <Link to={`/candidates/${event.candidate.id}`}>
          {buildTitle(event)}
        </Link>
      </div>
      {event.description}
    </div>
  );
}

export default function MyCalendar({ events }) {
  const handleSelectEvent = (e) => {
    console.log(e);
    //alert('TODO Delete Appointment with id: '+e.id);
    //TODO Delete Appointment
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={(event) => handleSelectEvent(event)}
        style={{ height: 500 }}
        min={moment("8:00am", "h:mma").toDate()}
        max={moment("9:00pm", "h:mma").toDate()}
        components={{
          event: Event,
          agenda: {
            event: EventAgenda
          }
        }}
      />
    </div>
  );
}
