import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useMutation } from "@apollo/client";
import { CREATE_NOTE } from "../../../Queries/Note";
import { toast } from "react-toastify";

export default function AddCandidateNoteForm({ handleClose, candidateId }) {
  //console.log("AddCandidateNoteForm", candidateId);
  const [note, setNote] = useState("");
  const [noteType, setNoteType] = useState("note");
  const [noteTypes] = useState(["call", "email", "sms", "note"]);
  const [processing, setProcessing] = useState(false);
  const [addNote] = useMutation(CREATE_NOTE);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (noteType && note) {
      setProcessing(true);

      addNote({
        variables: {
          note: {
            note: note,
            note_type: noteType,
            candidate_id: candidateId
          }
        }
      })
        .then((response) => {
          setNote("");
          setNoteType("");
          setProcessing(false);
          toast.success("Successfully added note");
          //window.location.reload();
          handleClose();
        })
        .catch((err) => {
          setProcessing(false);
          toast.error("Error adding note");
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="outlined-select-currency"
        select
        label="Select"
        value={noteType}
        onChange={(event) => setNoteType(event.target.value)}
        sx={{ textTransform: "capitalize" }}
        helperText="Please select your note type"
      >
        {noteTypes.map((nt) => (
          <MenuItem
            key={nt}
            value={noteType}
            sx={{ textTransform: "capitalize" }}
          >
            {nt}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        margin="normal"
        required
        fullWidth
        id="note"
        label="Note"
        name="description"
        multiline
        rows={2}
        maxRows={4}
        value={note}
        onChange={(event) => setNote(event.target.value)}
      />

      <Button
        type="submit"
        disabled={processing}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Create Note
      </Button>
    </form>
  );
}
