import React, { useState, useEffect } from "react";
import { TextField, Button, MenuItem } from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_APPOINTMENT } from "../../../Queries/Appointment";
import { GET_USERS_BY_TEAM } from "../../../Queries/User";

import MomentAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { toast } from "react-toastify";
import momentTimezone from "moment-timezone";
import { useAuth } from "../../../../contexts/AuthContext";

export default function AddAppointmentForm({ handleClose, candidateId }) {
  const [processing, setProcessing] = useState(false);
  const [addAppointment] = useMutation(CREATE_APPOINTMENT);
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [teamUser, setTeamUser] = useState("");
  const { currentUser } = useAuth();
  const timeZoneFromServer = currentUser.timezone || "America/New_York";
  const { moment } = new MomentAdapter({ instance: momentTimezone });
  const dateWithTimeZone = moment().tz(timeZoneFromServer);
  const [appointmentDate, setAppointmentDate] = useState(dateWithTimeZone);

  let team = localStorage.getItem("team");
  if (team) team = JSON.parse(team);
  let teamId = parseInt(team.teamId);

  const { data: team_data } = useQuery(GET_USERS_BY_TEAM, {
    variables: {
      teamId
    }
  });
  const [teamUsers, setTeamUsers] = useState([]);

  useEffect(() => {
    if (team_data) {
      let results = team_data.getUsersByTeam;
      let list = [];
      for (let i = 0; i < results.length; i++) {
        list.push(results[i].User);
      }

      let userId = list[0].id;

      setTeamUsers(list);
      if (list.length === 1) {
        setTeamUser(userId);
      }
    }
  }, [team_data]);

  const handleChangeTeamUser = (event) => {
    setTeamUser(event.target.value);
    console.log("team user: ", event.target.value);
  };

  console.log("appointmentDate", appointmentDate);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (topic && description && candidateId && appointmentDate && teamUser) {
      setProcessing(true);

      addAppointment({
        variables: {
          appointment: {
            topic,
            description,
            appointment_date: appointmentDate,
            candidate_id: candidateId,
            user_id: parseInt(teamUser)
          }
        }
      })
        .then((response) => {
          setTopic("");
          setDescription("");
          setAppointmentDate("");
          setProcessing(false);
          toast.success("Successfully added appointment");
          handleClose();
        })
        .catch((err) => {
          setProcessing(false);
          toast.error("Error adding appointment");
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {teamUsers.length > 1 && (
        <>
          <TextField
            select
            margin="normal"
            label="Select User"
            value={teamUser}
            onChange={handleChangeTeamUser}
            helperText="Please select your user"
          >
            {teamUsers.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.first_name} {user.last_name}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
        </>
      )}

      <LocalizationProvider dateAdapter={MomentAdapter}>
        <DateTimePicker
          label="Appointment Date"
          value={appointmentDate}
          onChange={(newValue) => {
            setAppointmentDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <TextField
        margin="normal"
        required
        fullWidth
        id="topic"
        label="Meeting Topic"
        name="topic"
        value={topic}
        onChange={(event) => setTopic(event.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="description"
        label="Meeting Description"
        name="description"
        multiline
        rows={2}
        maxRows={4}
        value={description}
        onChange={(event) => setDescription(event.target.value)}
      />

      <Button
        type="submit"
        disabled={processing}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Create Appointment
      </Button>
    </form>
  );
}
