import React, { useEffect } from "react";
import "./App.css";

import HomePage from "./components/home/HomePage";

const prod = process.env.REACT_APP_PROD;

function App() {
    
    useEffect(()=>{
      if(!prod && window.location.protocol!=="https:"){
          window.location.replace(`https://${window.location.host}/${window.location.pathname}`)
      }
    })
    return (
      <>
      <HomePage />
      </>
    )
}

export default App;
