import React from "react";
import Login from "../auth/Login";
import Dashboard from "../dashboard/Dashboard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "../../styles/HomePage.css";
import { useAuth } from "../../contexts/AuthContext";
import { padding } from "@mui/system";

const HomePage = () => {
  const { currentUser } = useAuth();
  let logout = localStorage.getItem("logout");

  const checkDefaultDirectApplicable = () => {
    if (
      window.location.pathname.includes("privacy") ||
      window.location.pathname.includes("terms")
    ) {
      return true;
    }
    return false;
  };

  if (checkDefaultDirectApplicable()) {
    return (
      <div className="container-main">
        <Grid container>
          <Grid item sm={12} id="page-content-wrapper">
            <Dashboard />
          </Grid>
        </Grid>
      </div>
    );
  }

  if (!currentUser || logout === "true") {
    return (
      <div>
        <Grid container style={{ minHeight: "100vh" }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ height: "100vh" }}
            sx={{
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
              backgroundColor: "#f5f5f5"
            }}
          >
            <Box
              style={{
                height: "40vh",
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Box component="div">
                <img
                  src="/images/ilovescheduling.png"
                  style={{
                    width: "80%",
                    height: "80%",
                    objectFit: "contain",
                    paddingTop: "20%"
                  }}
                />
              </Box>
            </Box>
            <Box
              component="div"
              style={{
                paddingLeft: "10%",
                display: "flex",
                paddingTop: "20%",
                paddingRight: "20px",
                justifyContent: "center"
              }}
            >
              <img
                width="100"
                height="100"
                alt="gsuite-logo"
                src="/images/gsuite-logo.png"
              />
              <Box component="div">
                <ul>
                  <li>Single sign on with your Google account</li>
                  <li>Calendar appointments integrated with Google calendar</li>
                  <li>
                    View and Edit your Google calendar appointments without
                    leaving the application
                  </li>
                  <li>
                    Maximize your time by meeting with your appointment through
                    Google Video.
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <div className="login">
              <Login />
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ height: "200" }}
          sx={{ padding: "20px" }}
        >
          <a target="_blank" href="/privacy">
            Privacy
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a target="_blank" href="/terms">
            Terms
          </a>
        </Grid>
      </div>
    );
  }

  if (currentUser) {
    return (
      <div className="container-main">
        <Grid container>
          <Grid item sm={12} id="page-content-wrapper">
            <Dashboard />
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default HomePage;
