import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CandidateNote from "./CandidateNote";
import CandidateAppointments from "./CandidateAppointments";

export default function CandidateDetailTabs({ notes, appointments, refetch }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Activity" value="1" />
            <Tab label="Appointments" value="2" />
            <Tab label="Notes" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <CandidateNote notes={notes} />
        </TabPanel>
        <TabPanel value="2">
          <CandidateAppointments
            appointments={appointments}
            refetch={refetch}
          />
        </TabPanel>
        <TabPanel value="3">Notes</TabPanel>
      </TabContext>
    </Box>
  );
}
