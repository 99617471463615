import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import PersonIcon from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CANDIDATE, DELETE_CANDIDATE } from "../../Queries/Candidate";
import Tags from "../../tags/Tags";
import EditCandidateForm from "./form/EditCandidateForm";
import CandidateDetailTabs from "./CandidateDetailTabs";
import CandidateNoteModal from "./notes/CandidateNoteModal";
import AppointmentModal from "./appointments/AppointmentModal";
import SmsModal from "./SmsModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { formatPhoneNumber } from "react-phone-number-input";
import Link from "@mui/material/Link";

import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../styles/CandidateDetail.css";

export default function CandidateDetail() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [editCandidate, SetEditCandidate] = useState(false);
  const [deleteCandidate] = useMutation(DELETE_CANDIDATE);
  const { loading, error, data, refetch } = useQuery(GET_CANDIDATE, {
    variables: { candidateId: parseInt(id) }
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const handleBack = () => {
    navigate(`/candidates`);
  };

  const handleEdit = () => {
    SetEditCandidate(true);
  };

  const handleCloseEdit = () => {
    SetEditCandidate(false);
    refetch();
  };

  const handleDelete = () => {
    console.log("delete candidate", {
      variables: {
        candidateId: id
      }
    });
    deleteCandidate({
      variables: {
        candidateId: parseInt(id)
      }
    })
      .then((response) => {
        toast.success("Successfully deleted candidate");
        navigate(`/candidates`);
      })
      .catch((err) => {
        toast.error("Error deleting candidate");
      });
  };

  const handleDeleteConfirmation = () => {
    console.log("delete");
    confirmAlert({
      title: "Delete Candidate",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleDelete();
          }
        },
        {
          label: "No"
        }
      ]
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Link onClick={handleBack}>
          {" "}
          <span className="pt-5">
            <ArrowLeftIcon />
          </span>
          <span>Back</span>
        </Link>
        <Box component="div" className="pt-5 action-buttons">
          <SmsModal candidateId={data.getCandidate.id} refetch={refetch} />

          <CandidateNoteModal
            candidateId={data.getCandidate.id}
            refetch={refetch}
          />
          <AppointmentModal
            candidateId={data.getCandidate.id}
            refetch={refetch}
          />
        </Box>
      </Grid>
      <Grid item xs={8} sm={12} md={4}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {editCandidate ? (
            <EditCandidateForm
              candidate={data.getCandidate}
              handleCloseEdit={handleCloseEdit}
            />
          ) : (
            <React.Fragment>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button size="small" sx={{ margin: "0px" }}>
                  <EditIcon onClick={handleEdit} />
                </Button>
                <Button size="small">
                  <DeleteIcon onClick={handleDeleteConfirmation} />
                </Button>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {data.getCandidate.image ? (
                  <Avatar
                    src={data.getCandidate.image}
                    sx={{ width: 80, height: 80 }}
                  />
                ) : (
                  <PersonIcon sx={{ width: 80, height: 80 }} />
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <h3>
                  {data.getCandidate.first_name} {data.getCandidate.last_name}
                </h3>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                Email: {data.getCandidate.email}
                <br />
                Phone:{" "}
                {formatPhoneNumber(data.getCandidate.phone) ||
                  data.getCandidate.phone}
                <br />
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>Tags</Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start"
            }}
          >
            <Tags
              data={data.getCandidate.tags}
              candidateId={data.getCandidate.id}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={8} sm={12} md={8}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <CandidateDetailTabs
            notes={data.getCandidate.notes}
            appointments={data.getCandidate.appointments}
            candidateCreated={data.getCandidate.createdAt}
            refetch={refetch}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
