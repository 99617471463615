import { gql } from "@apollo/client";

export const CREATE_TAG = gql`
  mutation CreateTag($tag: TagInput!) {
    createTag(tag: $tag) {
      id
      name
    }
  }
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($tag: DeleteTagInput!) {
    deleteTag(tag: $tag)
  }
`;
