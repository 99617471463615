import React from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import moment from "moment";
import { purple, green } from "@mui/material/colors";
import EventIcon from "@mui/icons-material/Event";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@apollo/client";
import { DELETE_APPOINTMENT } from "../../Queries/Appointment";
import { useAuth } from "../../../contexts/AuthContext";

export default function CandidateAppointments({ appointments, refetch }) {
  const [deleteAppointment] = useMutation(DELETE_APPOINTMENT);
  const { currentUser } = useAuth();
  const timeZoneFromServer = currentUser.timezone || "America/New_York";

  const avatarType = (appointment) => {
    if (appointment.kept) {
      return (
        <Avatar sx={{ bgcolor: green[500] }}>
          <EventIcon />
        </Avatar>
      );
    } else {
      return (
        <Avatar sx={{ bgcolor: purple[500] }}>
          <EventIcon />
        </Avatar>
      );
    }
  };

  const handleAppointmentDelete = (appointmentId) => {
    deleteAppointment({
      variables: {
        appointmentId
      }
    }).then((response) => {
      console.log("response", response);
      refetch();
    });

    console.log("appointment", appointmentId);
  };

  return (
    <React.Fragment>
      {appointments.map((appointment) => (
        <Grid container key={appointment.id} sx={{ paddingBottom: "20px" }}>
          <Grid item xs={1} sm={1} md={1}>
            {avatarType(appointment)}
          </Grid>
          <Grid item xs={11} sm={11} md={11}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ paddingLeft: "12px" }}>
                {moment(parseInt(appointment.appointment_date))
                  .tz(timeZoneFromServer)
                  .format("MMM DD, YYYY h:mm a")}
              </Box>
              <DeleteIcon
                sx={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  paddingTop: "5px"
                }}
                onClick={() => handleAppointmentDelete(appointment.id)}
              />
            </Box>
            <Box sx={{ paddingLeft: "12px" }}>{appointment.topic}</Box>
            <Box
              sx={{
                paddingLeft: "12px",
                borderBottom: "1px dotted #b9b7b6",
                fontSize: "12px"
              }}
            >
              {appointment.description}
              <br />
              {appointment.google_html_calendar_link ? (
                <a
                  href={appointment.google_html_calendar_link}
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Google Calendar
                </a>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      ))}
    </React.Fragment>
  );
}
