import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "../typography/title";
import MyCalendar from "./MyCalendar";
import { GET_ALL_APPOINTMENTS } from "../Queries/Appointment";
import { useQuery } from "@apollo/client";
import AppointmentModal from "./AppointmentModal";
import Box from "@mui/material/Box";

export default function Appointments() {
  let team = localStorage.getItem("team");
  if (team) team = JSON.parse(team);

  const { loading, error, data, refetch } = useQuery(GET_ALL_APPOINTMENTS);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (data) {
      let eventsArray = data.getAllAppointments?.map((appointment) => {
        return {
          id: appointment.id,
          title: appointment.topic,
          start: new Date(parseInt(appointment.appointment_date)),
          end: new Date(parseInt(appointment.appointment_date) + 3600000),
          description: appointment.description,
          appointment_date: appointment.appointment_date,
          candidate: {
            id: appointment.candidate.id,
            first_name: appointment.candidate.first_name,
            last_name: appointment.candidate.last_name
          },
          allDay: false
        };
      });
      setEvents(eventsArray);
    }
  }, [data]);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log("events: ", events);
  return (
    <div>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Title>Appointments</Title>
              <AppointmentModal refetch={refetch} />
            </Box>
            <MyCalendar events={events} />
          </React.Fragment>
        </Paper>
      </Grid>
    </div>
  );
}
