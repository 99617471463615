import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AddCandidateNoteForm from "./AddCandidateNoteForm";
import NoteIcon from "@mui/icons-material/Note";
import Title from "../../../typography/title";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};

export default function CandidateNoteModal({ candidateId, refetch }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    refetch();
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" sx={{ mr: 2 }}>
        <NoteIcon />
        <Box component="span" sx={{ display: { xs: "none", sm: "block" } }}>
          {" "}
          Notes
        </Box>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Title>Create Note</Title>
          <Box sx={{ mt: 1 }}>
            <AddCandidateNoteForm
              handleClose={handleClose}
              candidateId={candidateId}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
