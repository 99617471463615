import React from "react";

export default function Privacy() {
  return (
    <div>
      <div>
        <h2 sx={{ whiteSpace: "pre-wrap" }}>Privacy policy.</h2>
        <p sx={{ whiteSpace: "pre-wrap" }}>Last updated May 5,2022</p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          I Love Scheduling (“we” or “us” or “our Service”) appreciates the
          importance of privacy. We want you to feel assured using our services,
          and this privacy notice (“Privacy Notice”, “Privacy Policy” or
          “Notice”) is to help you understand and describe how we collect, use,
          and disclose your information.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Throughout this document, we will use a few terms to describe various
          products and roles. All of the text, data, information, software,
          graphics, photographs, and more that we and our affiliates may make
          available to you, plus our websites and any services, plug-ins,
          software, or applications that we may provide all together constitute
          the I Love Scheduling “Website.” I Love Scheduling “Users” are the
          people who have created a I Love Scheduling account, and “I Love
          Scheduling Invitees” are people who have scheduled a meeting with a I
          Love Scheduling User but may or may not have registered with I Love
          Scheduling. A I Love Scheduling “Viewer” is a person who visits the I
          Love Scheduling Website but may or may not be a I Love Scheduling User
          or I Love Scheduling Invitee.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Any terms defined in Terms of Use, End User License Agreement, and
          Data Processing Addendum and not otherwise specified in this Privacy
          Notice is adopted by reference in this Privacy Notice.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Information We Collect</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We collect information about you directly from you and automatically
          through your use of our Website. We may combine the collected
          information about you, including from publicly available sources.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          To help you protect yourself and your information, we encourage to
          provide only necessary information for using our services. For
          example, to schedule a meeting, you may only need to give us a name,
          email address, date, and time.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          I Love Scheduling is not directed to children under thirteen (13)
          years of age, and we do not knowingly collect Personal Information
          from children under 13. If we discover that a child under 16 has
          provided us with Personal Information, we will promptly delete such
          Personal Information from our systems.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Information You Provide Voluntarily</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          I Love Scheduling collects the following information, either directly
          from I Love Scheduling Users, Invitees, or Viewers, or through
          third-parties regarding Users, Invitees, or Viewers.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>I Love Scheduling</strong>
        </p>
        <ul data-rte-list="default">
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Appointment Information. A I Love Scheduling User, Invitee, or a
              third-party may voluntarily give us certain information. This can
              include names, email addresses, phone numbers, email addresses of
              other people; the subject of the meeting; and any additional
              information provided to us or as required by the I Love Scheduling
              User upon scheduling I Love Scheduling Terms of Use. If you
              receive an invitation from a I Love Scheduling User but do not
              wish to become a I Love Scheduling User, please contact the I Love
              Scheduling User who sent the invitation through other means to set
              up your meeting.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Calendar Information. A I Love Scheduling User may connect their
              calendar, such as Google calendar, with I Love Scheduling. Our
              calendar integration only checks the duration and free/busy status
              of the events in your calendar to not book you when you’re busy.
              We never store whom you are meeting with, their email address, the
              meeting title, or any other details about the appointments in your
              connected calendar. Specifically in case of Google account, when
              you create a new I Love Scheduling account with your Google
              account, or use your Google Calendars to use our services, we will
              ask for your consent to connect your Google account to your I Love
              Scheduling account (using Google’s authentication methods),
              thereby providing us with access to your Google calendars.
              Although the authentication provides broad access to your Google
              account, I Love Scheduling only collects your free/busy times and
              the ID of your Google Calendars which are relevant to operating
              the I Love Scheduling service.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Billing Information. If you purchase a premium version of I Love
              Scheduling, our third-party payment processors will collect and
              store your billing address and credit card information. We store
              the last four digits of your credit card number, card type, and
              expiration date.
            </p>
          </li>
        </ul>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Google Services, Microsoft Services</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          When signing up for our Service with an email account hosted by Google
          (i.e. gmail.com or G-Suite) or Microsoft, you will be asked to give
          permission for our Service to have read-only access to your Calendars
          and Read/Write access to Events on your Calendars. The following
          explains the way in which our Service uses (and does NOT use) these
          permissions
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>We do not</strong>
        </p>
        <ul data-rte-list="default">
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Collect or store your Gmail/G-Suite/Google/Microsoft password, not
              even in an encrypted form
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Provide any way for you or our Service to edit, delete or
              otherwise alter any email messages from your account from within
              our Service
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Provide any way for you or our Service to edit, delete or
              otherwise alter the contents of any calendar event or contact from
              your account apart from the events managed by us
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Share, sell or otherwise disseminate any part of any calendar
              event or contact from your account to any third party or attempt
              to have any human read any part of any of these items
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Collect or aggregate any private or personally identifiable
              information from your Profile or any email message, calendar event
              or contact except as to provide the respective functionalities of
              our Service (detailed above).
            </p>
          </li>
        </ul>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>
            Detailed explanation of Google Calendar API Scopes we use
          </strong>
        </p>
        <ol data-rte-list="default">
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              I Love Scheduling needs ./auth/calendar.readonly scope because it
              needs to be able to show you the list of your Google Calendars and
              let you select which calendar(s) you want to be checked for
              conflicts.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Also, to synchronize the events booked from our Service to your
              calendar, I Love Scheduling needs to know which calendars you have
              "write" access to, and then show you a list of those calendars and
              let you select which calendar you would like to add new events to.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              I Love Scheduling also needs ./auth/calendar.events, to
              synchronize actions such as creating the event booked with you via
              our Service, updating the same event’s
              title/schedule/description/attendees, or deleting the same event,
              with that specific Google Calendar you have chosen as explained in
              2.
            </p>
          </li>
        </ol>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>
            Detailed explanation of Microsoft Calendar API Scopes we use
          </strong>
        </p>
        <ol data-rte-list="default">
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              I Love Scheduling needs Calendars.ReadWrite scope because it needs
              to be able to show you the list of your Microsoft Calendars and
              let you select which calendar(s) you want to be checked for
              conflicts.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Also, to synchronize the events booked from our Service to your
              calendar, I Love Scheduling needs to know which calendars you have
              "write" access to, and then show you a list of those calendars and
              let you select which calendar you would like to add new events to.
              In addition with this scope I Love Scheduling is able to
              add/remove the scheduled events on your calendar.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              I Love Scheduling requires OnlineMeetings.ReadWrite to provide
              Microsfot Teams as an event location.
            </p>
          </li>
        </ol>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Information Collected Automatically</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Like many websites, our Service Providers and we use Cookies, web
          beacons, and other technologies to receive and store certain types of
          information when you interact with us through your computer or mobile
          device, subject to your opt-out preferences (see Your Rights and
          Choices section below). Using these technologies helps us customize
          your experience with our Website, improve your experience, and tailor
          marketing messages. Here are some of the types of information we
          collect:
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Log &amp; Device Data</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We want to inform you that whenever you visit our Service, we collect
          information that your browser sends to us that is called Log Data.
          This Log Data may include information such as your computer’s Internet
          Protocol (“IP”) address, browser version, pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages, and other statistics. This anonymous log data is used to
          improve our Service and is not traceable to your personal identity. We
          do not share or sell this data to any third party for any reason.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Cookie Data</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          I Love Scheduling (“we” or “us” or “our Service”) use cookies and
          other technologies to tailor your experience on our services.
          Depending on how you’re accessing our services and subject to your
          opt-out preferences, we may use “Cookies” or similar technologies to
          record log data and enable better experience.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>What Cookies Are?</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Cookies are small blocks of data created while a user is browsing and
          visiting a website and placed on the user's computer or other devices
          using a browser. Cookies are then exchanged with visiting websites. It
          enables the website to recall your preferences, such as login,
          language, over a period of time.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Cookies are categorized into two:</strong>
        </p>
        <ol data-rte-list="default">
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Persistent: That lasts until you or your browser deletes them.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Session: That lasts until you close your browser.
            </p>
          </li>
        </ol>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>How I Love Scheduling Uses Cookies?</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          I Love Scheduling uses the both Persistent and Session Cookies. We use
          cookies in three different ways:
        </p>
        <ol data-rte-list="default">
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Authentication: With these cookies, we help you to save some time
              by keeping you logged in to I Love Scheduling. We can recognize
              that you are already logged in, and enable you to use the
              application directly without each time signing in.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Preferences: You tailored once your experience on our website and
              we would like to keep the experience according to your
              preferences. We use cookies to know which language you prefer.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Performance and Analytics: It's important to get insights about
              how our services are being used and accessed. We use other
              services such as Google Analytics or other third-party cookies, to
              assist with analyzing performance on our application. These
              service providers may use cookies to collect and store some
              information such as your device, time of visit, visited pages,
              clicks, IP address, and browser type.
            </p>
          </li>
          <li>
            <p sx={{ whiteSpace: "pre-wrap" }}>
              Other Web Site Analytics Services. Subject to your opt-out
              preferences (see ‘Your Rights and Choices’ below), we use
              third-party Service Providers such as Google Analytics to provide
              specific analytics and Viewer interactions services to I Love
              Scheduling in connection with our operation of our Website,
              including the collection and tracking of certain data and
              information regarding the characteristics and activities of
              visitors to I Love Scheduling. You may opt-out of relevant cookies
              using opt-out features on their respective websites.
            </p>
          </li>
        </ol>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>How We Use Your Information</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We may use information that we collect about you, including Personal
          Data, to:
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Provide the I Love Scheduling Service. We will use your information to
          provide our Website to you, including facilitating scheduling,
          managing your account, responding to your inquiries, and other
          customer service and support purposes. We use the payment information
          you provide to us to alert you of past, current, and upcoming charges,
          to allow us to present the billing history to you on your billing page
          on the Website, and to perform internal financial processes, such as
          looking at the status of a credit card charge. In the event of a
          credit card dispute, we also share account information with your bank
          to verify a charge’s legitimacy.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Understand and improve our products. We will perform research and
          analysis about your use of, or interest in, our products, services, or
          content, or products, services, or content offered by others. We do
          this to help make our products better and to develop new products.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Communicate with you.</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Service-related communications. We may send you service and
          administrative emails to ensure the service is working correctly. We
          will also email you regarding your calendar appointments. These
          messages are considered part of the service, and you may not opt-out
          of these messages.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Promotional. Subject to your opt-out preference, we may send you
          emails about new product features or other news about I Love
          Scheduling or on topics we think would be relevant to you. You may
          opt-out of receiving these communications at any time. Visit the ‘Your
          Rights and Choices’ section below. For I Love Scheduling Invitees,
          please be assured that we do not use the email addresses that you
          enter to schedule a meeting with a I Love Scheduling User to send any
          type of direct marketing.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Responding to your requests. We will also use your information to
          respond to your questions or comments.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Administrative. We may contact you to inform you about changes in your
          services, our service offering, and other important service-related
          notices, such as changes to the Notice or about security or fraud
          notices.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Protecting Rights and Interests. We will use your information to
          protect our rights and interests and the rights and interests of our
          Users and any other person and enforce this Notice or our Terms of
          Use.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Legal Compliance. We may use your information to comply with
          applicable legal or regulatory obligations, including complying with
          law enforcement requests or other governmental authorities or in legal
          proceedings involving I Love Scheduling.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Other. We also may use your information to manage our business or
          perform functions as otherwise described to you at the time of
          collection subject to your consent.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>With Whom We May Share Your Information</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We do not sell your information to any third parties or disclose it in
          exchange for money or other valuable consideration. We do not share
          your Personal Data with others except as indicated within this Notice
          or when we inform you and give you an opportunity to opt-out of having
          your Personal Data shared.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We will never use Invitee data to send direct marketing via emails,
          SMS, physical mailings, or other similar communication channels to
          advertise or promote the use of our product and services or those of a
          third-party.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We may share information we collect about you, including Personal
          Data, in the following ways:
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Third-party Service Providers, agents, contractors, or government
          entities.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We use other companies, agents, or contractors (“Service Providers”)
          to perform services on our behalf or to assist us with providing
          services to you:
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We may engage Service Providers to process credit card transactions or
          other payment methods. We may also engage Service Providers to provide
          services such as monitoring and developing I Love Scheduling services,
          aiding in communications, infrastructure, and IT services; customer
          service; debt collection; analyzing and enhancing data. These Service
          Providers may have access to your personal or other information to
          provide these functions. Besides, some of the above-listed types of
          information that we request may be collected by third-party Service
          Providers on our behalf.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We may share information with Service Providers and government
          entities for legal, security, and safety purposes. This includes
          sharing information to enforce policies or contracts, address security
          breaches, and assist in investigating fraud, security issues, or other
          concerns.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We require Service Providers to agree to take reasonable steps to keep
          the Personal Data that we provide securely. We do not authorize them
          to use or disclose your Personal Data except in connection with
          providing their services.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Affiliates</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We may disclose information to current or future affiliates or
          subsidiaries for research, marketing, and other purposes consistent
          with this Privacy Notice.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We may share your data if we believe that disclosure is reasonably
          necessary to comply with a law, regulation, legal, or governmental
          request; to respond to a subpoena, court order, warrant, or other
          legal processes; to enforce applicable Terms of Use or this Notice,
          including investigation of potential violations thereof; to protect
          the safety, rights, or property of the public, any person, or I Love
          Scheduling; to detect, prevent, or otherwise address, security or
          technical issues, illegal, or suspected illegal activities (including
          fraud); or as evidence in litigation in which we are involved, as part
          of a judicial or regulatory proceeding.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          If you are using I Love Scheduling as a paid member of an organization
          or using your organization’s email domain (thereby representing
          yourself as a member of the organization), we may share your email
          address and plan information with an authorized agent of your company
          upon request for them to administer the account for the company.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Your Rights and Choices</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Account</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          To keep your Personal Data accurate and complete, you can log in to
          review and update your account information, including contact and
          billing information, via your account settings page. You may also
          contact us to request information about the personal data we have
          collected from you and to request the correction, modification, or
          deletion of such Personal Data. We will do our best to honor your
          requests subject to any legal and contractual obligations. If you
          would like to make a request, cancel your account, or request we
          delete or no longer use your account information to provide you our
          Website, contact us at legal(at)ilovescheduling.com or the address set
          forth at the end of this Notice. Subject to applicable law, we will
          retain and use your account information only as necessary to comply
          with our legal obligations, resolve disputes, and enforce our
          agreements.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>E-mail</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          As described above, if you do not wish to receive promotional emails
          from us, you may opt-out at any time by following the opt-out link
          contained in the email itself. Please note that it may take up to ten
          (10) days to process your request. Please also note that if you
          opt-out of receiving marketing communications from us, we may continue
          sending you service-related emails that are not available for opt-out.
          If you do not wish to receive any service-related emails from us, you
          have the option to delete your account.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Cookies</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          You may also refrain from providing or may withdraw your consent for
          Cookies via your browser settings and via limited opt-out functions
          within I Love Scheduling. Your browser's help function should contain
          instructions on how to set your computer to accept all Cookies, to
          notify you when a Cookie is issued, or not to receive Cookies at any
          time. Please keep in mind that Cookies are required to authenticate I
          Love Scheduling Users and perform some actions within I Love
          Scheduling (such as to pay for an event as an Invitee via I Love
          Scheduling), so to use the Website, Cookies are often required.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Third-Party Analytics Services</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Some of the services used provide the ability to opt-out.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          You may opt-out of Google Analytics’ services using the Opt-Out
          feature on their website. Google Inc. provides Google Analytics
          service. You can opt-out from Google Analytics service using your
          information by installing the Google Analytics Opt-out Browser tool:
          https://tools.google.com/dlpage/gaoptout.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          For more information on the privacy practices of Google, please visit
          the Google Privacy &amp; Terms web page:
          https://www.google.com/policies/privacy.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          You may opt-out of Facebook Pixel’s services using their opt-out
          feature: https://www.facebook.com/help/568137493302217.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          For more information on the privacy practices of Facebook, please
          visit their Data Policy: https://www.facebook.com/about/privacy.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Additional Rights</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Subject to local law, you may have other rights under the laws of your
          jurisdiction regarding your personal data, such as the right to
          complain to your local data protection authority.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Security and Storage of Information</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We have taken reasonable steps to help protect the personal
          information we collect.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          I Love Scheduling takes the security of your personal data very
          seriously. We work hard to protect the personal data you provide from
          loss, misuse, and unauthorized access or disclosure. Given the nature
          of communications and information processing technology, there is no
          guarantee that personal data will be safe from access, alteration, or
          destruction by a breach of any of our physical, technical, and
          managerial safeguards.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          You should take steps to protect against unauthorized access to your
          device and account by, among other things, choosing a unique and
          complex password that nobody else knows or can easily guess and
          keeping your log-in and password private. We are not responsible for
          any lost, stolen, or compromised passwords or any activity on your
          account via unauthorized password activity.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          We retain the personal data we collect for so long as reasonably
          necessary to fulfill the purposes for which the data was collected,
          perform our contractual and legal obligations, and for any applicable
          statute of limitations periods to bring and defend claims.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Please be aware that information we collect (including through the use
          of methods such as Cookies and other web technologies) will be
          processed and stored in countries where our third-party Service
          Providers or we have operations. By submitting your personal data to I
          Love Scheduling and using I Love Scheduling, you expressly consent to
          have your personal data transferred to, processed, and stored in
          jurisdictions that may not offer the same level of privacy protection
          as those in the country where you reside or are a citizen.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Third-Party Links</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          Our website may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over, and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Changes to this Notice</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          This Notice is current as of the Effective Date set forth above. This
          Notice may change if there is a material change to the way information
          is handled at I Love Scheduling, or to clarify our Notice or adjust
          clerical errors. If any changes are made, we’ll post them on this
          page, so please check back periodically. If you continue to use I Love
          Scheduling after those changes are in effect, you agree to the revised
          Notice.
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          <strong>Contacting Us</strong>
        </p>
        <p sx={{ whiteSpace: "pre-wrap" }}>
          If you have any questions or comments about this Notice, please
          contact us at contact(at)iloveschedulingco.com.
        </p>
      </div>
    </div>
  );
}
