import React from "react";
import ReactPaginate from "react-paginate";

function Pagination({
  pageCount,
  limit,
  handlePageClick,
  forcePage,
  handleSetLimit
}) {
  return (
    <div className="flex">
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        className="pagination"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        forcePage={forcePage}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default Pagination;
