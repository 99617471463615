import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AddAppointmentForm from "./AddAppointmentForm";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Title from "../../../typography/title";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};

export default function AppointmentModal({ refetch, candidateId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    refetch();
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" sx={{ mb: 1 }}>
        <CalendarTodayIcon />
        <Box component="span" sx={{ display: { xs: "none", sm: "block" } }}>
          {" "}
          Appointment
        </Box>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Title>Create Appointment</Title>
          <Box sx={{ mt: 1 }}>
            <AddAppointmentForm
              handleClose={handleClose}
              candidateId={candidateId}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
