import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "../typography/title";
import Box from "@mui/material/Box";
import { GET_ALL_TOUCHES } from "../Queries/Report";
import { useQuery } from "@apollo/client";
import { BarChart, Bar, Tooltip, Legend, XAxis, YAxis } from "recharts";

export default function Touch() {
  const { loading, error, data } = useQuery(GET_ALL_TOUCHES);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log(data);

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <React.Fragment>
          <Title>Touches</Title>
          <Box
            xs={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            {data.getAllTouches ? (
              <BarChart width={600} height={400} data={data.getAllTouches}>
                <Bar dataKey="count" fill="#1976d2" />

                <XAxis dataKey="note_type" />
                <YAxis />
                <Tooltip />
                <Legend />
              </BarChart>
            ) : (
              <React.Fragment>
                <p>No touch data.</p>
              </React.Fragment>
            )}
          </Box>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
