import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SideBar from "../layout/SideBar";
import NotFound from "../notfound/NotFound";
import { AppBar, Drawer } from "../../utils/LayoutUtil";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Candidates from "../candidate/Candidates";
import CandidateDetail from "../candidate/detail/CandidateDetail";
import Privacy from "../legal/Privacy";
import Terms from "../legal/Terms";
import Tools from "../tools/Tools";
import Upload from "../tools/Upload";
import Appointments from "../appointments/Appointments";
import Reports from "../reports/Reports";
import Touch from "../reports/Touch";
import Profile from "../profile/Profile";
import Logout from "../auth/Logout";
import AdminTeams from "../admin/AdminTeams";
import AdminUsers from "../admin/AdminUsers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../contexts/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import Avatar from "@mui/material/Avatar";

// secondary: {
//       light: "#ff7961",
//       main: "#f44336",
//       dark: "#ba000d",
//       contrastText: "#000"
//     },
//     secondary: {
//       light: "#757ce8",
//       main: "#3f50b5",
//       dark: "#002884",
//       contrastText: "#fff"
//     }
function DashboardContent() {
  const { currentUser } = useAuth();

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  //prefersDarkMode ? "dark" :
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#fff"
      }
    }
  });
  let sideBarOpened = localStorage.getItem("sideBarOpened") || "true";
  if (!currentUser) sideBarOpened = "false";

  sideBarOpened = sideBarOpened === "true";

  const [open, setOpen] = React.useState(sideBarOpened);
  const toggleDrawer = () => {
    setOpen(!open);
    localStorage.setItem("sideBarOpened", !open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px"
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" })
              }}
            >
              {currentUser && <MenuIcon />}
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              I{" "}
              <span role="img" aria-label="heart">
                {`\u{2764}`}
              </span>{" "}
              Scheduling
            </Typography>
            <div className="flex">
              {currentUser && (
                <div>
                  {currentUser.image ? (
                    <img
                      className="profile-img"
                      src={currentUser.image}
                      alt="img"
                    />
                  ) : (
                    <Avatar />
                  )}
                </div>
              )}
              {currentUser && <Logout />}
            </div>
          </Toolbar>
        </AppBar>

        {currentUser && (
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1]
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <SideBar />
            </List>
          </Drawer>
        )}

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            paddingTop: "6em",
            paddingLeft: "3em",
            paddingRight: "5em"
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Candidates />}></Route>
              <Route
                path="candidates/:id"
                element={<CandidateDetail />}
              ></Route>
              <Route path="candidates" element={<Candidates />}></Route>
              <Route path="appointments/:id" element={<Appointments />}></Route>
              <Route path="appointments" element={<Appointments />}></Route>
              <Route path="reports" element={<Reports />}></Route>
              <Route path="reports/touch" element={<Touch />}></Route>
              <Route path="profile" element={<Profile />}></Route>
              <Route path="privacy" element={<Privacy />}></Route>
              <Route path="terms" element={<Terms />}></Route>
              <Route path="tools" element={<Tools />}></Route>
              <Route path="tools/candidate-upload" element={<Upload />}></Route>
              <Route path="admin/teams" element={<AdminTeams />}></Route>
              <Route
                path="admin/teams/:id/:team_name"
                element={<AdminUsers />}
              ></Route>
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </BrowserRouter>
        </Box>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme={"colored"}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
