import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../../contexts/AuthContext";
import { sleep } from "../../utils/Sleep";

export default function TeamSelect() {
  let current_teams = localStorage.getItem("currentTeams");
  const { currentTeam, currentUser } = useAuth();

  if (current_teams) {
    current_teams = JSON.parse(current_teams);
  }

  let [teams, setTeams] = useState([]);
  let [teamId, setTeamId] = useState(currentTeam?.teamId);
  let [teamName, setTeamName] = useState(currentTeam?.teamName);

  useEffect(() => {
    let team_name_list = [];
    let team_list = [];

    for (let i = 0; i < current_teams.length; i++) {
      if (current_teams[i].primary == null) continue;
      if (team_name_list.indexOf(current_teams[i].Team.name) < 0) {
        team_list.push(current_teams[i]);
        team_name_list.push(current_teams[i].Team.name);
      }
    }

    setTeams(team_list);
  }, []);

  const handleChangeTeam = async (event) => {
    let t = parseInt(event.target.value);
    let team = current_teams.find((team) => team.Team.id === t);

    setTeamId(team.Team.id);
    setTeamName(team.Team.name);

    let team_obj = { teamId: team.Team.id, teamName: team.Team.name };

    localStorage.removeItem("team");
    localStorage.setItem("team", JSON.stringify(team_obj));

    await sleep(1000);
    window.location.href = "/candidates";
  };

  const getValue = (team) => {
    return `${team.Team.name}`;
  };

  if (teams.length <= 1) {
    return "";
  }
  return (
    <TextField
      select
      margin="normal"
      required
      label="Team"
      className="team-select"
      onChange={handleChangeTeam}
      value={teamId}
    >
      {teams &&
        teams.map((team) => (
          <MenuItem key={team.team_id} value={team.team_id}>
            {getValue(team)}
          </MenuItem>
        ))}
    </TextField>
  );
}
