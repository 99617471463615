import { GoogleLogout } from "react-google-login";
import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-toastify";

export default function Logout() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { logout } = useAuth();

  const onSuccess = async () => {
    console.log("Logged out successfully");
    try {
      await logout();
      window.location.href = "/";
    } catch (err) {
      console.log("Error logging out");
      toast("Error logging out");
    }
  };

  return (
    <>
      <GoogleLogout
        clientId={clientId}
        buttonText="Logout"
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            style={{
              backgroundColor: "inherit",
              color: "white",
              border: "none",
              cursor: "pointer"
            }}
          >
            Logout
          </button>
        )}
        onLogoutSuccess={onSuccess}
      ></GoogleLogout>
    </>
  );
}
