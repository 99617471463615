import React, { useEffect } from "react";
import { Cancel } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_TAG, DELETE_TAG } from "../Queries/Tag";
import { toast } from "react-toastify";

const Tags = ({ data, handleDelete }) => {
  return (
    <Box
      sx={{
        background: "#673AB7",
        height: "100%",
        display: "flex",
        padding: "0.4rem",
        margin: "10px 0.5rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff"
      }}
    >
      <Stack direction="row" gap={1}>
        <Typography>{data.name}</Typography>
        <Cancel
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </Box>
  );
};

export default function InputTags({ data, candidateId }) {
  const [tags, setTags] = useState([]);
  const tagRef = useRef();
  const [addTag] = useMutation(CREATE_TAG);
  const [deleteTag] = useMutation(DELETE_TAG);

  useEffect(() => {
    setTags(data);
  }, [data]);

  const handleDelete = (value) => {
    console.log("handleDelete", value.id);
    deleteTag({
      variables: {
        tag: {
          candidateId: candidateId,
          tagId: value.id
        }
      }
    })
      .then((response) => {
        const newtags = tags.filter((val) => val !== value);
        setTags(newtags);
        toast.success("Successfully deleted tag");
      })
      .catch((err) => {
        toast.error("Error deleting tag");
      });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    addTag({
      variables: {
        tag: {
          candidateId: candidateId,
          name: tagRef.current.value
        }
      }
    })
      .then((response) => {
        setTags([...tags, response.data.createTag]);
        tagRef.current.value = "";
        toast.success("Successfully added tag");
      })
      .catch((err) => {
        toast.error("Error adding tag");
      });
  };
  return (
    <Box
      sx={{
        width: 300
      }}
    >
      <form onSubmit={handleOnSubmit}>
        <TextField
          inputRef={tagRef}
          fullWidth
          variant="standard"
          size="small"
          sx={{ margin: "1rem 0" }}
          margin="none"
          placeholder={tags?.length < 5 ? "Enter tags" : ""}
        />
        <Box
          sx={{
            margin: "0 0.2rem 0 0",
            display: "flex",
            flexWrap: "wrap"
          }}
        >
          {tags?.map((data, index) => {
            return <Tags data={data} handleDelete={handleDelete} key={index} />;
          })}
        </Box>
      </form>
    </Box>
  );
}
