import { gql } from "@apollo/client";

export const GET_ME = gql`
  query GetMe {
    getMe {
      id
      first_name
      last_name
      email
      image
      phone
    }
  }
`;

export const GET_USERS = gql`
  {
    getUsers {
      id
      name
      job_title
      email
    }
  }
`;

export const GET_USERS_BY_TEAM = gql`
  query GetUsersByTeam($teamId: Int!) {
    getUsersByTeam(teamId: $teamId) {
      team_id
      user_id
      primary
      User {
        id
        first_name
        last_name
        email
        image
        phone
      }
    }
  }
`;

export const GET_USERS_WITH_COUNT_BY_TEAM = gql`
  query GetUsersWithCountByTeam($teamId: Int!) {
    getUsersWithCountByTeam(teamId: $teamId) {
      team_id
      user_id
      primary
      User {
        id
        first_name
        last_name
        email
        image
        phone
        Candidates {
          id
        }
      }
    }
  }
`;


export const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      first_name
      last_name
      email
      image
      phone
    }
  }
`;

export const VIEW_USERS = gql`
  query ($id: Int) {
    getUserInfo(id: $id) {
      id
      name
      job_title
      email
    }
  }
`;

export const ADD_USER = gql`
  mutation RegisterOrLogin($user: RegisterInput!) {
    registerOrLogin(user: $user) {
      id
      first_name
      last_name
      email
      image
      access_token
      token
    }
  }
`;

export const EDIT_USER = gql`
  mutation UpdateUser($phone: String!) {
    updateUser(phone: $phone) {
      id
      first_name
      last_name
      email
      image
      phone
    }
  }
`;

export const DELETE_USER = gql`
  mutation ($id: Int) {
    deleteUser(id: $id)
  }
`;
