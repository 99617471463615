import { gql } from "@apollo/client";

export const CREATE_NOTE = gql`
  mutation CreateNote($note: NoteInput!) {
    createNote(note: $note) {
      id
      note
      note_type
      createdAt
    }
  }
`;
