import { gql } from "@apollo/client";

export const GET_ALL_APPOINTMENTS = gql`
  query GetAllAppointments {
    getAllAppointments {
      id
      topic
      description
      appointment_date
      kept
      google_calendar_id
      google_html_calendar_link
      candidate {
        id
        first_name
        last_name
        email
        phone
      }
    }
  }
`;

export const VIEW_USERS = gql`
  query ($id: Int) {
    getUserInfo(id: $id) {
      id
      name
      job_title
      email
    }
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation CreateAppointment($appointment: AppointmentInput!) {
    createAppointment(appointment: $appointment) {
      id
      topic
      description
      kept
      appointment_date
      google_calendar_id
      google_html_calendar_link
    }
  }
`;

export const DELETE_APPOINTMENT = gql`
  mutation DeleteAppointment($appointmentId: Int!) {
    deleteAppointment(appointmentId: $appointmentId) {
      status
    }
  }
`;
