import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../typography/title";
import { useQuery } from "@apollo/client";
import { GET_ALL_TEAMS } from "../Queries/Team";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export default function AdminTeams() {
  const { loading, error, data } = useQuery(GET_ALL_TEAMS);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to="/admin/teams"
          >
            Admin
          </Link>
          <Typography underline="hover" color="inherit">
            Teams
          </Typography>
        </Breadcrumbs>

        <React.Fragment>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Title>Teams</Title>
          </Box>
          <div
            className="table-container"
            style={{ height: "60vh", overflowY: "auto" }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell>Users</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.getAllTeams.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/admin/teams/${row.id}/${row.name}`}
                      >
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell>{row.Team_Users.length}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
