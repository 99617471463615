import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { CANDIDATE_SEND_SMS } from "../../Queries/Candidate";
import { toast } from "react-toastify";

export default function SendSmsForm({ candidateId, handleClose }) {
  let [smsSendQuery, { error, data }] = useLazyQuery(CANDIDATE_SEND_SMS);
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (message) {
      setProcessing(true);

      data = await smsSendQuery({
        variables: { candidateId, message }
      });

      if (error) {
        toast.error("Error sending sms");
        setProcessing(false);
      }
      if (data) {
        toast.success("Sms sent successfully");
        handleClose();
        setProcessing(false);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="sms-message"
        label="Sms Message"
        name="sms-message"
        multiline
        rows={2}
        maxRows={4}
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />

      <Button
        type="submit"
        disabled={processing}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Send Sms
      </Button>
    </form>
  );
}
