import React, { useState, useEffect } from "react";
import { TextField, Button, MenuItem } from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_APPOINTMENT } from "../Queries/Appointment";
import { GET_CANDIDATES } from "../Queries/Candidate";
import { GET_USERS_BY_TEAM } from "../Queries/User";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { toast } from "react-toastify";

export default function AddCandidateForm({ handleClose }) {
  const [processing, setProcessing] = useState(false);
  const [addAppointment] = useMutation(CREATE_APPOINTMENT);
  const [candidate, setCandidate] = useState("");
  const [teamUser, setTeamUser] = useState("");
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(new Date());

  let team = localStorage.getItem("team");
  if(team) team = JSON.parse(team);
  let team_id = team.teamId;

  const [teamId, setTeamId] = useState(team_id);

  const { loading, error, data, refetch } = useQuery(GET_CANDIDATES, {
    variables: { page: 0, limit: 1000, teamId: parseInt(teamId) }
  });

  const { data:team_data } = useQuery(GET_USERS_BY_TEAM,{
    variables: {
      teamId: parseInt(teamId)
    }
  });
  const [teamUsers, setTeamUsers] = useState([]);

  useEffect(() => {
    if (team_data) {
      let results = team_data.getUsersByTeam;
      let list = [];
      for(let i=0;i<results.length;i++){
          list.push(results[i].User)
      }

      let user = `${list[0].first_name} ${list[0].last_name}`
      let userId = list[0].id;

      setTeamUsers(list);

      if(list.length == 1){
        setTeamUser(userId)
      }
    }
  }, [team_data]);


  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const handleChange = (event) => {
    setCandidate(event.target.value);
    console.log("candidate: ", event.target.value);
  };

  const handleChangeTeamUser = (event)=>{
    setTeamUser(event.target.value)
    console.log("team user: ", event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`${topic} ${description} ${candidate} ${teamUser} ${appointmentDate}`);
    if (topic && description && candidate && appointmentDate && teamUser) {
      setProcessing(true);

      addAppointment({
        variables: {
          appointment: {
            topic,
            description,
            appointment_date: appointmentDate,
            candidate_id: candidate,
            user_id: parseInt(teamUser)
          }
        }
      })
        .then((response) => {
          setTopic("");
          setDescription("");
          setAppointmentDate("");
          setCandidate("");
          setProcessing(false);
          toast.success("Successfully added appointment");
          handleClose();
        })
        .catch((err) => {
          setProcessing(false);
          toast.error("Error adding appointment");
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="outlined-select-currency"
        select
        margin="normal"
        label="Select Candidate"
        value={candidate}
        onChange={handleChange}
        helperText="Please select your candidate"
      >
        {data.getAllCandidates.candidates.map((candidate) => (
          <MenuItem key={candidate.id} value={candidate.id}>
            {candidate.first_name} {candidate.last_name}
          </MenuItem>
        ))}
      </TextField>
      {teamUsers.length <= 1 && <br/>}

      {teamUsers.length>1 && <><TextField
        select
        margin="normal"
        label="Select User"
        value={teamUser}
        onChange={handleChangeTeamUser}
        helperText="Please select your user"
      >
        {teamUsers.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.first_name} {user.last_name}
          </MenuItem>
        ))}
      </TextField>
      <br/>
      <br/></>}

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          label="Appointment Date"
          value={appointmentDate}
          onChange={(newValue) => {
            setAppointmentDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <TextField
        margin="normal"
        required
        fullWidth
        id="topic"
        label="Meeting Topic"
        name="topic"
        value={topic}
        onChange={(event) => setTopic(event.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="description"
        label="Meeting Description"
        name="description"
        multiline
        rows={2}
        maxRows={4}
        value={description}
        onChange={(event) => setDescription(event.target.value)}
      />

      <Button
        type="submit"
        disabled={processing}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Create Appointment
      </Button>
    </form>
  );
}
