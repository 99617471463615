import { gql } from "@apollo/client";

export const GET_ALL_TOUCHES = gql`
  query GetAllTouches {
    getAllTouches {
      note_type
      count
    }
  }
`;
