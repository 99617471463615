import React, { useState } from "react";
import Title from "../typography/title";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "@apollo/client";
import { GET_USERS_BY_TEAM } from "../Queries/User";
import Button from "@mui/material/Button";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";

export default function Upload() {
  const [userAssigned, setUserAssigned] = useState("");
  const [csvFile, setCsvFile] = useState();
  const [processing, setProcessing] = useState(false);
  const { currentTeam } = useAuth();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_USERS_BY_TEAM, {
    variables: {
      teamId: currentTeam.teamId
    }
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    let t = event.target.value;
    const token = JSON.parse(localStorage.getItem("currentUser"))?.user.token;

    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("userAssigned", userAssigned);
    formData.append("teamId", currentTeam.teamId);
    const importCandidatesUrl = `${process.env.REACT_APP_API_BASE_URL}/import/candidates`;
    axios({
      method: "POST",
      url: importCandidatesUrl,
      data: formData,
      headers: {
        authorization: token,
        Accept: "application/json",
        "Content-Type": "multipart/form-data"
      }
    })
      .then((response) => {
        setCsvFile("");
        setUserAssigned("");
        setProcessing(false);
        toast.success("Successfully imported candidates");
      })
      .catch((err) => {
        setProcessing(false);
        toast.error("Error importing candidates");
      });
  };

  const handleChange = async (event) => {
    setUserAssigned(event.target.value);
  };

  const changeFileHandler = async (event) => {
    console.log(event.target.files[0]);
    setCsvFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <React.Fragment>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Title>Upload Candidates</Title>
          </Box>
          <Box as="div">
            Please upload a csv file with the column headers (first_name,
            last_name, email, phone).
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%"
            }}
          >
            <form onSubmit={handleSubmit}>
              <ListItemButton>
                <TextField
                  select
                  required
                  label="Assign to User"
                  helperText="Select a user to assign candidates"
                  onChange={handleChange}
                  value={userAssigned}
                  fullWidth
                >
                  {data.getUsersByTeam.map((user) => (
                    <MenuItem key={user.User.id} value={user.User.id}>
                      {user.User.first_name} {user.User.last_name}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItemButton>
              <TextField
                margin="normal"
                required
                fullWidth
                type="file"
                accept=".csv"
                id="file"
                helperText="Upload a CSV file"
                name="file"
                valu={csvFile}
                onChange={changeFileHandler}
                autoFocus
              />
              <Button
                type="submit"
                disabled={processing}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Import Candidates
              </Button>
            </form>
          </Box>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
