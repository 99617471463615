import React from "react";
import Title from "../typography/title";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQuery } from "@apollo/client";
import { GET_ME } from "../Queries/User";
import EditProfileForm from "./form/EditProfileForm";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import { formatPhoneNumber } from "react-phone-number-input";

export default function Profile() {
  const { loading, error, data, refetch } = useQuery(GET_ME);
  const [editUser, setEditUser] = React.useState(false);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const handleCloseEdit = () => {
    setEditUser(false);
    refetch();
  };

  const handleEdit = () => {
    setEditUser(true);
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <React.Fragment>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button size="small" sx={{ margin: "0px" }}>
              <EditIcon onClick={handleEdit} />
            </Button>
          </Box>
          {editUser ? (
            <React.Fragment>
              <Title>Profile</Title>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <EditProfileForm
                  user={data.getMe}
                  handleCloseEdit={handleCloseEdit}
                />
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Title>Profile</Title>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Avatar
                    alt={`${data.getMe.first_name} ${data.getMe.last_name}`}
                    src={data.getMe.image}
                    sx={{ width: 56, height: 56 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="label-section">Name</div>
                  {data.getMe.first_name} {data.getMe.last_name}
                </Grid>
                <Grid item xs={12}>
                  <div className="label-section">Email</div>
                  {data.getMe.email}
                </Grid>
                <Grid item xs={12}>
                  <div className="label-section">Phone</div>
                  {formatPhoneNumber(data.getMe.phone) ||
                    data.getMe.phone ||
                    "--- --- ----"}
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
