import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { CREATE_CANDIDATE } from "../Queries/Candidate";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

export default function AddCandidateForm({ handleClose }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [processing, setProcessing] = useState(false);
  const [addCandidate] = useMutation(CREATE_CANDIDATE);

  let current_teams = localStorage.getItem("currentTeams");

  let team = localStorage.getItem("team");
  if (team) team = JSON.parse(team);
  let team_id = team.teamId;
  let team_name = team.teamName;

  if (current_teams) {
    current_teams = JSON.parse(current_teams);
  }

  let [teams, setTeams] = useState(current_teams);
  let [teamId, setTeamId] = useState(team_id);
  let [teamName, setTeamName] = useState(team_name);

  useEffect(() => {
    console.log(phone);
  }, [phone]);

  const handleChange = (event) => {
    let t = event.target.value;
    let team_id = null;

    for (let i = 0; i < teams.length; i++) {
      if (teams[i].Team.name === t) {
        team_id = teams[i].team_id;
      }
    }

    setTeamId(team_id);
    setTeamName(t);
  };

  const getValue = (team) => {
    return `${team.Team.name}`;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (firstName && lastName && email && phone && teamId) {
      setProcessing(true);

      addCandidate({
        variables: {
          candidate: {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            team_id: teamId
          }
        }
      })
        .then((response) => {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setTeamName("");
          setTeamId("");
          setProcessing(false);
          toast.success("Successfully added candidate");
          window.location.reload();
          handleClose();
        })
        .catch((err) => {
          setProcessing(false);
          toast.error("Error adding candidate");
        });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <h4>{teamName}</h4>

      <TextField
        margin="normal"
        required
        fullWidth
        id="first_name"
        label="First Name"
        name="first_name"
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="last_name"
        label="Last Name"
        name="last_name"
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        type="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />

      <PhoneInput
        country={"us"}
        value={phone}
        onChange={(value) => setPhone(value)}
        inputProps={{
          name: "phone",
          id: "phone",
          placeholder: "Phone",
          required: true
        }}
      />
      <Button
        type="submit"
        disabled={processing}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Create Candidate
      </Button>
    </form>
  );
}
