import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import BarChartIcon from "@mui/icons-material/BarChart";
import ConstructionIcon from "@mui/icons-material/Construction";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TeamSelect from "./TeamSelect";
import "../../styles/Layout.css";
import { useAuth } from "../../contexts/AuthContext";

export default function SideBar() {
  const { currentUser } = useAuth();

  const handleClick = (link) => {
    let origin = window.location.origin;
    window.location.href = origin + link;
    localStorage.removeItem("pageno");
  };

  return (
    <React.Fragment>
      <ListItemButton onClick={() => handleClick("/candidates")}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Candidates" />
      </ListItemButton>

      <ListItemButton onClick={() => handleClick("/appointments")}>
        <ListItemIcon>
          <DateRangeIcon />
        </ListItemIcon>
        <ListItemText primary="Appointments" />
      </ListItemButton>

      <ListItemButton onClick={() => handleClick("/reports")}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>

      <ListItemButton onClick={() => handleClick("/tools")}>
        <ListItemIcon>
          <ConstructionIcon />
        </ListItemIcon>
        <ListItemText primary="Tools" />
      </ListItemButton>

      <ListItemButton onClick={() => handleClick("/profile")}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItemButton>

      {currentUser.role === "admin" && (
        <ListItemButton onClick={() => handleClick("/admin/teams")}>
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
        </ListItemButton>
      )}

      {currentUser && <TeamSelect />}
    </React.Fragment>
  );
}
