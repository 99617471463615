import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { EDIT_CANDIDATE } from "../../../Queries/Candidate";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

export default function EditCandidateForm({ candidate, handleCloseEdit }) {
  const [firstName, setFirstName] = useState(candidate.first_name);
  const [lastName, setLastName] = useState(candidate.last_name);
  const [email, setEmail] = useState(candidate.email);
  const [phone, setPhone] = useState(candidate.phone);
  const [processing, setProcessing] = useState(false);
  const [editCandidate] = useMutation(EDIT_CANDIDATE);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (firstName && lastName && email && phone) {
      setProcessing(true);

      editCandidate({
        variables: {
          candidate: {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            id: candidate.id
          }
        }
      })
        .then((response) => {
          setProcessing(false);
          toast.success("Successfully updated candidate");
          handleCloseEdit();
        })
        .catch((err) => {
          setProcessing(false);
          toast.error("Error updating candidate");
        });
    }
    handleCloseEdit();
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="first_name"
        label="First Name"
        name="first_name"
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="last_name"
        label="Last Name"
        name="last_name"
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        type="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <PhoneInput
        country={"us"}
        value={phone}
        onChange={(value) => setPhone(value)}
        inputProps={{
          name: "phone",
          id: "phone",
          placeholder: "Phone",
          required: true
        }}
      />

      <Button
        type="submit"
        disabled={processing}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Update Candidate
      </Button>
    </form>
  );
}
