import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";

const AuthContext = React.createContext();

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const componentMonted = useRef(true);

  let sessionActive = false;
  let loggedInTime = localStorage.getItem("sessionTime");

  if (loggedInTime) {
    loggedInTime = parseInt(loggedInTime);
    let current_time = new Date().getTime();
    if (loggedInTime + 24 * 60 * 60 * 1000 > current_time) {
      sessionActive = true;
    }
  }

  useEffect(() => {
    if (componentMonted.current) {
      if (window.localStorage.getItem("currentUser")) {
        setCurrentUser(
          JSON.parse(window.localStorage.getItem("currentUser")).user
        );
        setCurrentTeam(JSON.parse(window.localStorage.getItem("team")));
      }
      setLoading(false);
    }
    return () => {
      componentMonted.current = false;
    };
  }, []);

  const login = async (code) => {
    const loginUrl = `${process.env.REACT_APP_API_BASE_URL}/users/login`;
    const response = await axios.post(loginUrl, {
      code
    });
    localStorage.setItem("currentUser", JSON.stringify(response.data));
    localStorage.setItem(
      "currentTeams",
      JSON.stringify(response.data.user.teams)
    );
    let primary_team = response.data.user.teams.filter(
      (team) => team.primary === true
    );

    let team = {
      teamId: primary_team[0].team_id,
      teamName: primary_team[0].Team.name
    };

    localStorage.setItem("team", JSON.stringify(team));
    localStorage.setItem("sessionTime", new Date().getTime());
    localStorage.removeItem("logout");

    console.log("response.data: ", response.data);
    setCurrentUser(JSON.stringify(response.data));
    setCurrentTeam(primary_team[0].Team);
  };

  const logout = async () => {
    console.log("in authcontect.logout");
    localStorage.setItem("logout", "true");

    if (!sessionActive) {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("team");
      localStorage.removeItem("currentTeams");
      localStorage.removeItem("sessionTime");
    }
  };

  const inCognitoLogin = async (id) => {
    const adminUrl = `${process.env.REACT_APP_API_BASE_URL}/admin`;
    const response = await axios.post(adminUrl, {
      userId: id
    });

    const userInfo = {
      user: {
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        email: response.data.email,
        image: response.data.image,
        token: response.data.token
      }
    };

    localStorage.clear();
    localStorage.setItem("currentUser", JSON.stringify(userInfo));
    setCurrentUser(userInfo);
  };

  const value = {
    currentUser,
    currentTeam,
    login,
    logout,
    inCognitoLogin
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
