import { gql } from "@apollo/client";

export const GET_ALL_TEAMS = gql`
  query GetAllTeams {
    getAllTeams {
      id
      name
      Team_Users {
        user_id
        team_id
      }
    }
  }
`;
