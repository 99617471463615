import { gql } from "@apollo/client";

export const CANDIDATE_FIELDS = gql`
  fragment CandidateFields on Candidate {
    id
    first_name
    last_name
    email
    image
    phone
    team_id
  }
`;
