import React, { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-toastify";

export default function Login() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const { login } = useAuth();

  let sessionActive = false;
  let loggedInTime = localStorage.getItem("sessionTime");

  if (loggedInTime) {
    loggedInTime = parseInt(loggedInTime);
    let current_time = new Date().getTime();
    if (loggedInTime + 24 * 60 * 60 * 1000 > current_time) {
      sessionActive = true;
    }
  }

  let currentUser = localStorage.getItem("currentUser");
  let firstName = "";
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    firstName = currentUser.user.first_name;
  }

  const onSuccess = async (res) => {
    const { code } = res;
    try {
      let l = await login(code);
      window.location.href = "/candidates";
    } catch (err) {
      toast.error("Error logging in");
    }
  };

  const onFailure = (res) => {
    toast.error(`Error logging in ${res}`);
  };

  let client;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.onload = initClient;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initClient = async () => {
    console.log("initClient");
    client = window.google.accounts.oauth2.initCodeClient({
      client_id: clientId,
      scope:
        "profile email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.settings.readonly",
      ux_mode: "popup",
      callback: async (response) => {
        let { code } = response;
        console.log("code: ", code);

        await login(code);
        window.location.reload();
      }
    });
  };

  const handleLogin = () => {
    //console.log("handleLogin");
    client.requestCode();
  };

  const handleLoginWithSession = () => {
    localStorage.removeItem("logout");
    window.location.reload();
  };

  if (sessionActive) {
    return (
      <div className="flex pointer" onClick={handleLoginWithSession}>
        <img src="images/google-logo.png" className="google-alt-icon" />
        <div className="google-alt">Continue with {firstName}</div>
      </div>
    );
  }

  if (!sessionActive) {
    return (
      <div>
        <img
          src="images/btn_google_signin_dark_normal_web.png"
          onClick={handleLogin}
          style={{
            cursor: "pointer"
          }}
        />
      </div>
    );
  }
}
