import React from "react";
import Title from "../typography/title";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

export default function Tools() {
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <React.Fragment>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Title>Tools</Title>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%"
            }}
          >
            <Link
              component={RouterLink}
              to={"/tools/candidate-upload"}
              underline="hover"
            >
              Upload Candidates
            </Link>
          </Box>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
