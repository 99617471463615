import React, { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../typography/title";
import { useQuery } from "@apollo/client";
import { GET_USERS_WITH_COUNT_BY_TEAM } from "../Queries/User";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import { formatPhoneNumber } from "react-phone-number-input";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

export default function AdminUsers() {
  const [teamId, setTeamId] = useState(parseInt(useParams().id));
  const [teamName, setTeamName] = useState(useParams().team_name);

  const { loading, error, data, refetch } = useQuery(GET_USERS_WITH_COUNT_BY_TEAM, {
    variables: {
      teamId
    }
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <React.Fragment>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/admin/teams"
            >
              Admin
            </Link>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/admin/teams"
            >
              Teams
            </Link>
            <Typography underline="hover" color="inherit">
              Users
            </Typography>
          </Breadcrumbs>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Title>{teamName} Users</Title>
          </Box>
          <div
            className="table-container"
            style={{ height: "60vh", overflowY: "auto" }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Candidates</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.getUsersWithCountByTeam.length > 0 ? (
                  data.getUsersWithCountByTeam.map((row) => (
                    <TableRow key={row.user_id}>
                      <TableCell>
                        {row.User.image ? (
                          <Avatar src={row.User.image} />
                        ) : (
                          <Avatar sx={{ bgcolor: deepOrange[500] }}>
                            {row.User.first_name[0].toUpperCase()}
                          </Avatar>
                        )}
                      </TableCell>
                      <TableCell>{row.user_id}</TableCell>
                      <TableCell>
                        <Link
                          to={`/admin/teams/${teamId}/${teamName}/users/${row.user_id}`}
                        >
                          {row.User.first_name} {row.User.last_name}
                        </Link>
                      </TableCell>
                      <TableCell>{row.User.email}</TableCell>
                      <TableCell>
                        {formatPhoneNumber(row.User.phone) || row.User.phone}
                      </TableCell>
                      <TableCell>
                        {row.User.Candidates.length || 0}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>No users found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
