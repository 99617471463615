import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { EDIT_USER } from "../../Queries/User";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

export default function EditProfile({ user, handleCloseEdit }) {
  const [phone, setPhone] = useState(user.phone);
  const [processing, setProcessing] = useState(false);
  const [editUser] = useMutation(EDIT_USER);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (phone) {
      setProcessing(true);

      editUser({
        variables: {
          phone: phone
        }
      })
        .then((response) => {
          setProcessing(false);
          toast.success("Successfully updated profile");
          handleCloseEdit();
        })
        .catch((err) => {
          setProcessing(false);
          toast.error("Error updating profile");
        });
    }
    handleCloseEdit();
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="first_name"
        label="First Name"
        name="first_name"
        value={user.first_name}
        autoFocus
        disabled={true}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="last_name"
        label="Last Name"
        name="last_name"
        value={user.last_name}
        disabled
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        type="email"
        value={user.email}
        disabled
      />
      <PhoneInput
        country={"us"}
        value={phone}
        onChange={(value) => setPhone(value)}
        inputProps={{
          name: "phone",
          id: "phone",
          placeholder: "Phone",
          required: true
        }}
      />

      <Button
        type="submit"
        disabled={processing}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Update Profile
      </Button>
    </form>
  );
}
