import { gql } from "@apollo/client";
import { CANDIDATE_FIELDS } from "./Fragments";

export const GET_CANDIDATES = gql`
  ${CANDIDATE_FIELDS}
  query GetAllCandidates(
    $page: Int!
    $limit: Int!
    $teamId: Int!
    $orderColumn: String
    $orderBy: String
  ) {
    getAllCandidates(
      page: $page
      limit: $limit
      teamId: $teamId
      orderColumn: $orderColumn
      orderBy: $orderBy
    ) {
      total
      candidates {
        ...CandidateFields
      }
    }
  }
`;

export const GET_CANDIDATE = gql`
  ${CANDIDATE_FIELDS}
  query GetCandidate($candidateId: Int!) {
    getCandidate(candidateId: $candidateId) {
      ...CandidateFields
      appointments {
        id
        topic
        description
        kept
        appointment_date
        google_calendar_id
        google_html_calendar_link
      }
      tags {
        id
        name
      }
      notes {
        id
        note
        note_type
        createdAt
      }
    }
  }
`;

export const VIEW_USERS = gql`
  query ($id: Int) {
    getUserInfo(id: $id) {
      id
      name
      job_title
      email
    }
  }
`;

export const CANDIDATE_SELECT_BOX = gql`
  query GetAllCandidates {
    getAllCandidates {
      id
      first_name
      last_name
    }
  }
`;

export const CANDIDATE_SEND_SMS = gql`
  query SendSms($candidateId: Int!, $message: String!) {
    sendSms(candidateId: $candidateId, message: $message) {
      status
    }
  }
`;

export const CREATE_CANDIDATE = gql`
  mutation CreateCandidate($candidate: CandidateInput!) {
    createCandidate(candidate: $candidate) {
      id
      first_name
      last_name
      email
      phone
      team_id
    }
  }
`;

export const EDIT_CANDIDATE = gql`
  mutation Mutation($candidate: UpdateCandidateInput!) {
    updateCandidate(candidate: $candidate) {
      id
      first_name
      last_name
      email
      phone
      team_id
    }
  }
`;

export const DELETE_CANDIDATE = gql`
  mutation DeleteCandidate($candidateId: Int!) {
    deleteCandidate(candidateId: $candidateId) {
      status
    }
  }
`;
